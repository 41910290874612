//import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/investors`;
//const categorySubject = new BehaviorSubject(null);

export const investorService = {
    create
};

export function create(investor) {
    return axios.post(`${baseUrl}/registrations`, investor , {
        headers:{'Content-Type': 'application/json'}
      })
        .then(response => response.data).catch(function(err){
            console.log(err);
            });
}
