<template>
    <div class="">
      <div class="row third-banner-principal">
        <div class="col-md-3 offset-md-4  offset-sm-4 col-sm-6 offset-4 col-6 container-banner-info">
        <h2 class="title-principal-banner">Grow with us</h2>
        </div>
        <div class="col-md-3 ocultarpequeno">
          <img class="img-principal-banner" alt="logo" src="../assets/globo.png">
        </div>
      </div>
      <div class="espacio"></div>
      <div class="row">

        <div class="col-md-6 ocultarpequeno">
            <iframe
                class="iframeVideo" 
                id="" 
                src="https://www.youtube.com/embed/q-81zG8Qp10"
                frameborder="0"
                loop="1"
                >
            </iframe>
        </div>
        <div class="col-md-6 container-invest-form">
            <div class="invest-form">
                <h2 class="title-principal-banner-blue">Invest with us</h2>
                <InvestForm class="select-categories"></InvestForm>
                <!--<button class="btn-send">
                    Send / Enviar
                </button>-->
            </div>
        </div>

      </div>
  </div>
  </template>
  
  <script>
  import InvestForm from '@/components/investWithUs/InvestForm.vue';
  
  export default {
      components:{ InvestForm },
      name:'InvestWithUs'
  }
  </script>
  
  <style>
  
    .third-banner-principal {
      background-color: #397ccd;
      height: 350px;
    }
  
    .container-banner-info{
      margin-top: 150px;
      text-align: left;
    }
  
    .img-principal-banner{
      margin-left: -50px;
      height: 300px;
    }
  
    .select-categories{
      margin-bottom: 50px;
    }

    .container-invest-form{
        background-color: #f6f7f9;
    }

    .invest-form{
        width: 75%;
        margin: 0 auto;
    }
    .iframeVideo{
        width: 100%;
        height: 100%;
    }


  @media screen and (max-width: 770px){
      
      /*.img{
        display: none;
      }*/

      .ocultarpequeno{
        display: none;
      }

      .login-content{
        justify-content: center;
      }

}
  </style>