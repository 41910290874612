<template>
    <div class="container">
      <div class="row">
          <div class="col-md-6 mb-3">
              <input v-model="firstName" type="text" class="form-control" placeholder="Name / Nombre">
          </div>
          <div class="col-md-6 mb-3">
              <input v-model="lastName" type="text" class="form-control" placeholder="Last name / Apellido">
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="email" type="email" class="form-control" placeholder="Email">
          </div>
          <div class="col-md-12 mb-3">
              <input  v-model="phoneNumber" type="text" class="form-control" placeholder="Telephone / Telefono">
          </div>
          <div class="col-md-12 mb-3">
              <input v-model="location"  type="text" class="form-control" placeholder="Location / Ubicación">
          </div>

      </div>
      <button :disabled="enviando" @click="crearUsuario" class="btn-send separar">
        <label v-if="!enviando">Send / Enviar</label>
        <span v-if="enviando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <label v-if="enviando">Enviando...</label>
      </button>
    </div>
  </template>
  
  <script>
    import {investorService} from '@/services/investors.service.js';
    import Swal from 'sweetalert2';
  export default {
      name:'InvestForm',
      props:['language'],
      data() {
            return {
                firstName:'',
                lastName:'',
                email:'',
                phoneNumber:'',
                location:'',
                enviando:false
            }
        },
        methods: {
            limpiarCampos(){
                this.firstName='',
                this.lastName='',
                this.email='',
                this.phoneNumber='',
                this.location=''
            },
            crearUsuario(){
                var request={
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phoneNumber: this.phoneNumber,
                    location: this.location
                };
                this.enviando=true;
                investorService.create(request).then(() => {
                    this.enviando=false;
                    Swal.fire(
              'Felicidades, ahora eres parte LetZTrav!',
              'Te enviaremos un correo para informarte sobre los siguientes pasos.',
              'success'
				);
                this.limpiarCampos();
                })
                .catch(error => {
                    this.enviando=false;
                    this.error = error;
                    this.loading = false;
                });
            },
        }
  }
  </script>
  
  <style>
      .title-container-options{
          text-align: left;
      }
      .title-box-option{
          color: #002fa5;
      }
      .optionsBox{
          text-align: left;
          margin-top: 15px;
          margin-bottom: 20px;
      }
      .label-checkbox{
          margin-left: 10px;
      }
      .title-form{
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
          color: gray;
      }
  </style>